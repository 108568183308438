.hero {
  display: flex;
  height: 100vh;
  background-color: black;

  .content {
    margin-top: 275px;
    margin-left: 275px;
    // position: absolute;
    display: flex;
    flex-direction: column;
    width: fit-content;
    

    .title {
      margin: 0;
      color: #fa9d88;
      font-weight: 700;
      font-size: 86px;
      margin-bottom: -80px;
      background-color: black;
      width: fit-content;
      position: relative;

      span {
        color: #ff6846;
      }

      &::after {
        content: "|";
        position: absolute;
        right: 0;
        width: 100%;
        color: #ff522c;
        background: black;
        animation: typing 3s steps(16) forwards,
        caret 1s infinite;
      }

      // Animation
      @keyframes typing {
        to { width: 0 }
      }
      @keyframes caret {
        50% { color: transparent }
      }
    }

    .subcontent {
      animation-duration: 2s;
      animation-fill-mode: both;
      animation-name: fadeInBottom;
      color: #fdd5cc;

      @keyframes fadeInBottom {
        from {
            opacity: 0;
            transform: translateY(100%);
        }
        to { opacity: 1 }
      }

      .subheading {
        font-size: 48px;
        font-weight: 300;
        margin: 0;
        margin-bottom: -20px;
      }
  
      .caption {
        font-size: 22px;
        font-weight: 400;
        margin: 0;
        max-width: 700px;
        width: fit-content;
        line-height: 33px;
        word-spacing: 10px;
      }
    }
    
    .button {
      line-height: 0;
      margin-top: 40px;
      padding: 20px 30px 20px 30px;
      border: 0;
      border-radius: 7px;
      background-color: transparent;
      font-family: 'NTR', sans-serif;
      color: #ff522c;
      border: 3px solid #ff522c;
      font-size: 22px;
      transition: box-shadow 0.5s, background-color 0.5s;

      &:hover {
        cursor: pointer;
        background: #ff522c;
        color: white;
        box-shadow: 0 0 5px #ff522c, 0 0 25px #ff522c, 0 0 50px #ff522c,
          0 0 100px #ff522c;
      }
    }
  }
}

.particles-js {
  background-color: rgb(0, 0, 0);
  // position: absolute;
  // top: 0;
  // left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}

@media screen and (max-width: 1250px) {
  .hero {
    align-items:initial;
    padding-top: 200px;
    margin-bottom: 550px;
    height: fit-content;

    .content {
      margin: 0;
      margin-top: -75px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media screen and (max-width: 980px) {
  .hero {
    margin-bottom: 1000px;
  }
}