.img {
    border-radius: 50%;
    background-color: white;
    margin-left: 5px;
    margin-top: 5px;
    height: 50px;
    width: 50px;
}

@media screen and (max-width: 1170px) {
    .img {
        margin-left: 2.45px;
        margin-top: 2.45px;
        height: 35px;
        width: 35px;
    }
}