.experience-container {
    background-color: rgb(0, 0, 0);
    color: white;
    padding: 100px 100px 150px 100px;

    h1 {
        font-size: 46px;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        font-weight: 700;
        color: #fa9d88;

        span {
            color: #FF522C;
        }
    }

    .content {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;

        .links-container {
            display: flex;
        }
        
        .links {
            display: flex;
            flex-direction: column;
            padding: 0;
            margin-right: 100px;
    
            p {
                color: #fdd5cc;
                font-size: 22px;
                font-weight: 400;
                line-height: 33px;
                word-spacing: 10px;
                border-right: 2px solid #000000;
                width: 100%;
                padding: 5px 30px 5px 0px;
                margin-top: 10px;
    
                &:hover {
                    cursor: pointer;
                }
            }
    
            .current-link {
                color: #ff6846;
                border-right: 2px solid #FF522C;
                transition: color 2s, border-right 1s;
            }
        }

        .link-content {
            color: #fdd5cc;

            h2 {
                margin-top: 0;
                margin-bottom: 0;

                span {
                    color: #ff6846;
                }
            }

            .date {
                margin: 0;
                margin-top: -10px;
                margin-bottom: 20px;
            }

            .current-experience {
                opacity: 1;

                .point {
                    animation-duration: 2s;
                    animation-fill-mode: both;
                    animation-name: fadeInBottom;
                    
                    @keyframes fadeInBottom {
                        from {
                            opacity: 0;
                            transform: translateY(100%);
                        }
                        to { opacity: 1 }
                    }
                }
            }

            .experience {
                opacity: 0;

                .point {
                    position: absolute;
                }
            }

            .point {
                display: flex;
                margin-top: 5px;
                margin-bottom: 5px;

                .arrow {
                    width: 22px;
                    fill: #FF522C;
                    margin-right: 5px;
                    margin-top: 5px;
                }

                p {
                    max-width: 750px;
                    font-size: 22px;
                    line-height: 33px;
                    margin: 0;
                    margin-left: 5px;
                }
            }


        }
    }

    .vericalChanging {
        margin-top: 100px;
        color: rgb(26, 26, 26);
        width: fit-content;
        margin-left: auto;
        margin-right: auto;

        a {
            font-size: 20px;
            color: #FF522C;
            text-decoration: underline;
        }
    }
}

@media screen and (max-width: 1220px) {
    .experience-container {
        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .links-container  {
                margin-bottom: 20px;

                .links {
                    flex-direction: row;
                    margin-right: 0;
                    text-align: center;

                    p {
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 20px;
                        margin-right: 20px;
                        padding: 5px 10px 5px 10px;
                        border-right: none;
                        border-bottom: 2px solid #000000;
                    }

                    .current-link {
                        border-bottom: 2px solid #FF522C;
                        border-right: none;
                        transition: color 2s, border-bottom 1s;
                    }
                }
            }
        }
    }
}
