.contact-section {
  height: 800px;
  padding-top: 250px;
  padding-bottom: 0px;  
  background-color: #000000;
  position: relative;
}

.contact-container {
  position: absolute;
  bottom: 10%;
  left: 50%;
  width: 700px;
  padding: 40px;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  border-radius: 10px;

  h2 {
    font-size: 46px;
    font-weight: 700;
    color: #fa9d88;
    margin: 0 0 30px;
    padding: 0;
    text-align: center;

    span {
      color: #FF522C;
    }
  }

  form {
    .user-box {
      position: relative;

      input {
        width: 100%;
        padding: 10px 0;
        font-size: 16px;
        color: #fdd5cc;
        margin-bottom: 30px;
        border: none;
        border-bottom: 1px solid #fdd5cc;
        border-radius: 0;
        outline: none;
        background: transparent;

        &:focus ~ label {
          top: -20px;
          color: #ff522c;
          font-size: 12px;
        }
      }

      textarea {
        width: 100%;
        height: 100px;
        resize: none;
        padding: 10px 0;
        font-size: 16px;
        color: #fdd5cc;
        margin-bottom: 30px;
        border: none;
        border-bottom: 1px solid #fdd5cc;
        border-radius: 0;
        outline: none;
        background: transparent;

        &:focus ~ label {
          top: -20px;
          color: #ff522c;
          font-size: 12px;
        }
      }

      label {
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px 0;
        font-size: 16px;
        color: #fdd5cc;
        pointer-events: none;
        transition: 0.5s;
      }
    }

    .bottom-container {
      display: flex;

      button {
        background-color: transparent;
        border: none;
        position: relative;
        display: inline-block;
        padding: 10px 20px;
        color: #ff522c;
        font-size: 16px;
        text-decoration: none;
        text-transform: uppercase;
        transition: 0.5s;
        margin-top: 40px;
        letter-spacing: 4px;
        overflow: hidden;
  
        &:hover {
          background: #ff522c;
          color: white;
          border-radius: 5px;
          box-shadow: 0 0 5px #ff522c, 0 0 25px #ff522c, 0 0 50px #ff522c,
            0 0 100px #ff522c;
        }
  
        span {
          position: absolute;
          display: block;
        }
  
        span:nth-child(1) {
          top: 0;
          left: -100%;
          width: 100%;
          height: 2px;
          background: linear-gradient(90deg, transparent, #ff522c);
          animation: btn-anim1 1s linear infinite;
        }
  
        span:nth-child(2) {
          top: -100%;
          right: 0;
          width: 2px;
          height: 100%;
          background: linear-gradient(180deg, transparent, #ff522c);
          animation: btn-anim2 1s linear infinite;
          animation-delay: 0.25s;
        }
  
        span:nth-child(3) {
          bottom: 0;
          right: -100%;
          width: 100%;
          height: 2px;
          background: linear-gradient(270deg, transparent, #ff522c);
          animation: btn-anim3 1s linear infinite;
          animation-delay: 0.5s;
        }
  
        span:nth-child(4) {
          bottom: -100%;
          left: 0;
          width: 2px;
          height: 100%;
          background: linear-gradient(270deg, transparent, #ff522c);
          animation: btn-anim4 1s linear infinite;
          animation-delay: 0.75s;
        }
  
        @keyframes btn-anim1 {
          0% {
            left: -100%;
          }
          50%,
          100% {
            left: 100%;
          }
        }
        
        @keyframes btn-anim2 {
          0% {
            top: -100%;
          }
          50%,
          100% {
            top: 100%;
          }
        }
        
        @keyframes btn-anim3 {
          0% {
            right: -100%;
          }
          50%,
          100% {
            right: 100%;
          }
        }
        
        @keyframes btn-anim4 {
          0% {
            bottom: -100%;
          }
          50%,
          100% {
            bottom: 100%;
          }
        }      
      }

      .p-hidden {
        color: #ff522c;
        opacity: 0;
        margin-top: 50px;
        margin-left: 30px;
      }

      .p-showing {
        color: #ff522c;
        opacity: 1;
        transition: 1.5s;
        margin-top: 50px;
        margin-left: 30px;
      }
    }
  }
}

.socials {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-top: 600px;
    margin-left: auto;
    margin-right: auto;

    .social {
        width: 40px;
        height: 40px;
        box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
        margin-right: 20px;
        margin-left: 20px;
        text-align: center;
        line-height: 50px;
        font-size: 30px;
        text-decoration: none;
        position: relative;
        background-color: rgb(0, 0, 0);
        padding: 10px;
        border-radius: 50%;
        transition: 0.5s;

        &:hover {
            background-color: #ff522c;
            box-shadow: 0 0 5px #ff522c, 0 0 25px #ff522c, 0 0 50px #ff522c,
          0 0 100px #ff522c;
            transform: scale(1.09);
            cursor: pointer;

            a {
                svg {
                    fill: white
                }
            }
        }
    }   
}
