.project-container {
    padding-top: 50px;
    padding-bottom: 150px;
    width: 100vw;
    background-color: rgb(0, 0, 0);
    color: white;

    h1 {
        font-size: 46px;
        font-weight: 700;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        color: #fa9d88;

        span {
            color: #FF522C;
        }
    }

    .cards {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-right: auto;
        margin-left: auto;

        .card {
            position: relative;
            margin: 0 50px 50px 50px;
            display: grid;
            place-items: center;

            &:hover {
                cursor: pointer;

                .face1 {
                    background: #FF522C;
                    transform: translateY(0);
                    box-shadow: none;

                    .content {
                        opacity: 1;
                    }
                }

                .face2 {
                    transform: translateY(0);
                }
            }

            .face1 {
                width: 410px;
                height: 300px;
                position: relative;
                background: rgb(0, 0, 0);
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                transition: 0.5s;
                transform: translateY(200px);
                box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
 
                .content {
                    opacity: 0.2;
                    transition: 0.5s;
                    display: grid;
                    place-items: center;

                    svg {
                        margin: auto;
                    }

                    img {
                        width: 200px;
                        height: 200px;
                        fill: white;
                        margin: auto;
                    }

                    h3 {
                        margin: 10px 0 0;
                        padding: 0;
                        color: white;
                        text-align: center;
                        font-size: 1.5em;
                    }
                }
            }

            .face2 {
                width: 410px;
                height: 300px;
                transition: 0.5s;
                position: relative;
                background: white;
                color: black;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px;
                box-sizing: border-box;
                box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
                transition: transform 0.5s;
                transform: translateY(-100px);

                p {
                    margin: 0;
                    padding: 0;
                }

                a {
                    margin-top: 5px;
                    color: #FF522C;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}