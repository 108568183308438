.about {
    padding: 20px 100px 150px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: #fa9d88;

    h1 {
        font-size: 46px;
        width: fit-content;
        font-weight: 700;
        span {
            color: #ff522c;
        }
    }

    .content {
        color: #fdd5cc;
        display: flex;
        justify-content: center;
        width: fit-content;
        margin-top: 20px;

        img {
            margin: 0;
            margin-right: 50px;
            margin-top: 30px;
        }

        .text-container {
            margin: 0;
            margin-left: 50px;
            // width: 50%;
            max-width: 1000px;
            font-size: 22px;
            font-weight: 400;
            line-height: 33px;
            word-spacing: 10px;
        }
    }
}

@media screen and (max-width: 1400px) {
    .about
     {
         .content {
             display: grid;
             place-items: center;

             img {
                 margin-top: -40px;
                 margin-bottom: 10px;
                 margin-right: 0;
             }
             
         }
     }
}

@media screen and (max-width: 780px) {
    .about
    {
        margin-top: 500px;
    }
}